<template>
  <v-card class="mb-7" elevation="0" outlined tile>
    <v-card-text class="pa-5">
      <div v-if="elementVisible">
        <vue-apex-charts type="donut" height="400" :options="chartOptions" :series="series"></vue-apex-charts>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'CumplimientoPL',
  props: {
    datos: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    series: [],
    chartOptions: {
      labels: ['NO', 'SI'],
      chart: {
        type: 'donut',
        toolbar: {
          show: true
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function(val) {
          let valor = parseFloat(val).toFixed(0);
          return valor + '%';
        }
      },
      title: {
        text: '% DE CUMPLIMIENTO PLANES VS LOGROS',
        align: 'center',
        margin: 5,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: '18px',
          fontWeight: 'normal',
          fontFamily: 'Roboto, Helvetica, Arial',
          color: 'rgba(0, 0, 0, 0.8)'
        }
      },
      legend: {
        show: true,
        position: 'top',
        fontSize: '14px',
        fontFamily: 'Roboto, Helvetica, Arial',
        markers: {
          width: 14,
          height: 14,
          strokeWidth: 0,
          strokeColor: '#fff',
          fillColors: undefined,
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0
        },
        labels: {
          colors: '#424242',
          useSeriesColors: false
        },
        itemMargin: {
          horizontal: 15,
          vertical: 5
        },
        onItemHover: {
          highlightDataSeries: true
        },
        onItemClick: {
          toggleDataSeries: true
        }
      },
      grid: {
        padding: {
          left: 0,
          right: 0
        },
        borderColor: 'rgba(0,0,0,0.5)'
      },
      plotOptions: {
        pie: {
          customScale: 1.0,
          donut: {
            size: '65%',
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: '18px',
                color: undefined,
                offsetY: -10
              },
              value: {
                show: true,
                color: '#99abb4',
                formatter: function(w) {
                  return w + '%';
                }
              },
              total: {
                show: true,
                label: 'TOTAL',
                color: '#99abb4',
                formatter: function(w) {
                  let total = w.globals.seriesTotals[0] + w.globals.seriesTotals[1];
                  return total + '%';
                }
              }
            }
          }
        }
      },
      stroke: {
        width: 1,
        colors: 'rgba(0,0,0,0.1)'
      },
      colors: ['#d90429', '#27A300'],
      tooltip: {
        fillSeriesColor: false,
        y: {
          formatter: function(value) {
            return value + '%';
          }
        }
      },
      responsive: []
    },
    elementVisible: false
  }),
  computed: {
    getseries() {
      return this.series;
    }
  },
  watch: {
    async datos(val) {
      let sumaCumplimiento = 0;
      let cumplimiento = 0;
      let realizadas = 0;
      let norealizadas = 0;
      if (val.totaltareasareas != undefined) {
        if (val.totaltareasareas.length > 0) {
          let s1 = 0;
          let s2 = 0;
          let cont = 0;
          await val.totaltareasareas.forEach((element) => {
            if (element.idsubarea != 'GH') {
              cont++;
              sumaCumplimiento += element.cumplimiento;
            }
          });
          cumplimiento = sumaCumplimiento / cont;
          realizadas = parseInt(cumplimiento);
          norealizadas = parseInt(100 - realizadas);
          s1 = realizadas;
          s2 = norealizadas;
          this.series = [s2, s1];
        } else {
          this.series = [];
        }
        this.elementVisible = true;
      }
    }
  },
  created() {},
  components: {
    VueApexCharts
  }
};
</script>
